import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { Modal,Box,TextField,Button,Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { useHistory } from "react-router-dom";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import useCompanies from "../../hooks/useCompanies";
import usePlans from "../../hooks/usePlans";
import { i18n } from "../../translate/i18n";
import { openApi } from "../../services/api";
import Grid from "@material-ui/core/Grid";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import SignUp from "../../pages/Signup";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import moment from "moment";
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));
const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const CompModal = ({open, handleClose, handleAddCompany ,editEmpresa}) => {
	const { save: saveCompany } = useCompanies()
	const history = useHistory();
	const [company,setCompany]=useState({name:"",phone:"",email:""})
	const classes = useStyles();
	const initialState = { name: "", email: "", phone: "", password: "", planId: "", };

	const [user] = useState(initialState);
	const handleChangeInput = e => {
		setCompany({ ...company, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (editEmpresa) {
			setCompany(editEmpresa)
		}
	}, [editEmpresa])
	const dueDate = moment().add(1, "day").format();
	const [plans, setPlans] = useState([]);
	const { list: listPlans } = usePlans();
	const handleSignUp = async values => {
		Object.assign(values, { recurrence: "MENSAL" });
		Object.assign(values, { dueDate: dueDate });
		Object.assign(values, { status: "t" });
		Object.assign(values, { campaignsEnabled: true });
		try {
			await openApi.post("/companies/cadastro", values);
			toast.success(i18n.t("signup.toasts.success"));
			history.push("/login");
		} catch (err) {
			console.log(err);
			toastError(err);
		}
	};
	useEffect(() => {
		async function fetchData() {
			const list = await listPlans();
			setPlans(list);
		}
		fetchData();
	}, []);


	
	// const handleChange = (event) => {
	// 	const {name, value} = event.target
	// 	setFormData(prevData => ({ ...prevData, [name]: value }));
	// };
	// const handleSubmit = async (event) => {
	// 	event.preventDefault();
	// 	handleAddCompany(formData);
		
	// 	console.log(formData.name)
	// 	console.log(formData.email)

	// 	try {
	// 		await saveCompany(formData);
    //         setSuccess('Empresa cadastrada com sucesso!');
	// 		setFormData({name:'', email:''})
	// 		localStorage.removeItem('formData');	
	// 	} catch (err) {
	// 		setError('Erro ao cadastrar a empresa')
	// 	}
	// 	handleClose();
	// }
	
	const handleSubmit = async e => {
		e.preventDefault();
		try {
			const response = await saveCompany(company)
			console.log("Resposta da API:", response)
			handleAddCompany(response)
			setCompany({name:"",phone:"",email:""})
			toast.success(i18n.t("companies.form.success"));
		} catch (e) {
			toast.error(i18n.t("companies.form.error"));
		} finally {
			handleClose()
		}
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-title"
			aria-describedby="model-description"
		>
			<Box
				sx={{
					position:"absolute",
					top:'50%',
					left:'50%',
					transform:'translate(-50%,-50%)',
					width: 400,
					bgcolor:'background.paper',
					borderRadius:1,
					boxShadow:24,
					p: 4,
				}}
			>
				<Typography variant="h6" component="h2">
					Cadastrar Empresa
				</Typography>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSignUp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form className={classes.form}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Field
										as={TextField}
										autoComplete="name"
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										fullWidth
										id="name"
										label="Nome da Empresa"
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										id="email"
										label={i18n.t("signup.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										autoComplete="email"
										required
									/>
								</Grid>
								
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										id="phone"
										label="Telefone com (DDD)"
										name="phone"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										autoComplete="phone"
										required
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="password"
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										label={i18n.t("signup.form.password")}
										type="password"
										id="password"
										autoComplete="current-password"
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel htmlFor="plan-selection">Plano</InputLabel>
									<Field
										as={Select}
										variant="outlined"
										fullWidth
										id="plan-selection"
										label="Plano"
										name="planId"
										required
									>
										{plans.map((plan, key) => (
										
											<MenuItem key={key} value={plan.id}>
												{plan.name} - Atendentes: {plan.users} - WhatsApp: {plan.connections} - Filas: {plan.queues} - R$ {plan.value}
											</MenuItem>
										))}
									</Field>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="mensalidade"
										label="Mensalidade"
										type="number"
										id="mensalidade"
										InputProps={{ inputProps: { min: 1 } }}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="numeroatendimentos"
										label="Numero de Atendimentos contratados"
										type="number"
										id="numeroatendimentos"
										InputProps={{ inputProps: { min: 1 } }}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="valoratendimentoexce"
										label="Valor por atendimento excedente"
										type="number"
										id="valoratendimentoexce"
										InputProps={{ inputProps: { min: 1 } }}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="desconto"
										label="Desconto"
										type="number"
										id="desconto"
										InputProps={{ inputProps: { min: 0 } }}
										required
									/>
								</Grid>
								
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								{i18n.t("signup.buttons.submit")}
							</Button>
							
						</Form>
					)}
				</Formik>
			</Box>

		</Modal>

	)
}
export default CompModal
