import React from "react"
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  texto: {
    color: theme.palette.primary.main
  }
}))
const DataFiltro = ({ dateFrom, dateTo }) => {
  const classes = useStyles();
  

    return (
      <div className={classes.texto}>
        Periodo : {dateFrom} a {dateTo}
      </div>
    )
  }

  export default DataFiltro;