import React, { useState, useEffect ,useContext} from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import { AuthContext } from "../../context/Auth/AuthContext";
const CustomLegend = ({ payload }) => {
  return (
    <div style={{ color: 'black', fontSize: '10px' }}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} style={{ marginBottom: '5px' }}>
          <span
            style={{
              backgroundColor: entry.payload.fill,
              width: '10px',
              height: '10px',
              display: 'inline-block',
              marginRight: '5px',
            }}
          />
          <span>{`${entry.payload.categoria}: ${entry.payload.quantidade}`}</span>
        </div>
      ))}
    </div>
  );
};

const Chart4 = ({dateFrom,dateTo}) => {
  const [dados, setDados] = useState([]);
  const [dadosParaGrafico, setDadosParaGrafico] = useState([]);
  const [totalAtendimentos, setTotalAtendimentos] = useState(0);
  const [company, setCompany] = useState(null);
	const { user } = useContext(AuthContext); 
	const nomedaempresa = company
	useEffect(() => {
		if (user && user.company) {
		  setCompany(user.company.name); 
		}
	  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://apiupsoft.upcall.com.br/registros/query', {
          "query": {
            "DataHora_InicioAtend_ISO": {
              "$gte": dateFrom + "T00:00:00.000Z",
              "$lte": dateTo + "T23:59:59.999Z"
            },
            "Provedor": nomedaempresa
          }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        });

        const data = response.data;
        setDados(data);

        // Contar ocorrências de cada Diagnostico_Finalizacao
        const contagem = data.reduce((acc, item) => {
          const diagnostico = item.Diagnostico_Finalizacao;
          if (diagnostico) {  // Verifica se o diagnóstico não é undefined ou null
            acc[diagnostico] = (acc[diagnostico] || 0) + 1;
          }
          return acc;
        }, {});

        // Transformar a contagem em formato de gráfico, excluindo itens com quantidade 0 e chaves irrelevantes
        const dadosParaGrafico = Object.keys(contagem)
          .filter(chave => chave.trim() && contagem[chave] > 0) // Filtra chaves vazias ou com valor 0
          .map(chave => ({
            categoria: chave,
            quantidade: contagem[chave]
          }));

        setDadosParaGrafico(dadosParaGrafico);
        const total = dadosParaGrafico.reduce((acc, item) => acc + item.quantidade, 0);
        setTotalAtendimentos(total);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, [dateFrom, dateTo,nomedaempresa]);

  const COLORS = ['#0088FE','#384049','#BFD641', '#7F5571','#4A46BD','#DC4444','#8F1EAF','#E6089C','#00C49F', '#FFBB28', '#FF8042','#61DA20','#6F965A','#E21314','#EAC11C','#1E9716','#5DE2E7','#CC6CE7','#FE9900','#060270'];
  const PADDING_ANGLE = 5;
  const INNER_RADIUS = 40;
  return (
    <React.Fragment>
    <ResponsiveContainer width="100%" height="100%" style={{color:"black"}}>
      <h1>Diagnóstico de Finalização</h1>
     
      <PieChart width={600} height={600}>
        <Pie
          data={dadosParaGrafico}
          cx="50%"
            cy="50%"
          dataKey="quantidade"
          nameKey="categoria"
          outerRadius={240}
          fill="#8884d8"
          label
          paddingAngle={PADDING_ANGLE}
          innerRadius={INNER_RADIUS}
        >
          {dadosParaGrafico.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{color:"black"}}/>
          ))}
        </Pie>
        <Tooltip />
        <Legend
        wrapperStyle={{ fontSize: '11px', lineHeight: '16px' , color:"white"}}
        layout="vertical"
        align="right"
        content={<CustomLegend />}
      />
      </PieChart>
      </ResponsiveContainer>
      </React.Fragment>
  );
};

export default Chart4;
