import React, { useState, useEffect } from "react";
import Title from "../../components/Title";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper";
import StoreIcon from "@material-ui/icons/Store";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import MainContainer from "../../components/MainContainer";
import { i18n } from "../../translate/i18n";
import useCompanies from "../../hooks/useCompanies";
import usePlans from '../../hooks/usePlans';
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CompModal from "../../components/CompaniesModal";
import { Table } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";


const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(2),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	table: {
		maxHeight: '600px' ,
		overflowX: 'auto',
	},
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	  },
}));

const Companies = () => {
	const classes = useStyles();
	// const { getPlanList } = usePlans()
    // const { save : saveCompany } = useCompanies()
	// const [company, setCompany] = useState({ name: "", planId: "", token: "" });
	//  const [plans, setPlans] = useState([])

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		const list = await getPlanList()
	// 		setPlans(list);
	// 	}
	// 	fetchData();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const handleChangeInput = e => {
	// 	setCompany({ ...company, [e.target.name]: e.target.value });
	// };

	// const handlSubmit = async e => {
	// 	e.preventDefault();
	// 	try {
	// 		await saveCompany(company)
	// 		setCompany({ name: "", planId: "", token: "" })
	// 		toast.success(i18n.t("companies.form.success"));
	// 	} catch (e) {
	// 		toastError(e)
	// 	}
	// };

	// const renderPlanField = () => {
	// 	if (plans.length) {
	// 		return <>
	// 			<Grid item>
	// 				<FormControl fullWidth variant="outlined">
	// 					<InputLabel>Plano</InputLabel>
	// 					<Select 
	// 						required
	// 						id="planId"
	// 						label={i18n.t("companies.form.plan")}
	// 						name="planId"
	// 						value={company.planId}
	// 						onChange={handleChangeInput}
	// 						autoComplete="plan"
	// 					>
	// 						<MenuItem value={""}>&nbsp;</MenuItem>
	// 						{ plans.map((plan, index) => {
	// 							return <MenuItem value={plan.id} key={index}>{ plan.name }</MenuItem>
	// 						})}
	// 					</Select>
	// 				</FormControl>
	// 			</Grid>
	// 		</>
	// 	}
	// }

	// const renderNameField = () => {
	// 	if (plans.length && !isEqual(company.planId, "")) {
	// 		return <>
	// 			<Grid item>
	// 				<TextField
	// 					variant="outlined"
	// 					required
	// 					fullWidth
	// 					id="name"
	// 					label={i18n.t("companies.form.name")}
	// 					name="name"
	// 					value={company.name}
	// 					onChange={handleChangeInput}
	// 					autoComplete="name"
	// 					autoFocus
	// 				/>
	// 			</Grid>
	// 		</>
	// 	}
	// }

	// const renderTokenField = () => {
	// 	if (plans.length && !isEqual(company.planId, "")) {
	// 		return <>
	// 			<Grid item>
	// 				<TextField
	// 					variant="outlined"
	// 					required
	// 					fullWidth
	// 					id="token"
	// 					label={i18n.t("companies.form.token")}
	// 					name="token"
	// 					value={company.token}
	// 					onChange={handleChangeInput}
	// 					autoComplete="token"
	// 					autoFocus
	// 				/>
	// 			</Grid>
	// 		</>
	// 	}
	// }

	// const renderSubmitButton = () => {
	// 	if (plans.length && !isEqual(company.planId, "")) {
	// 		return <>
	// 			<Button
	// 				type="submit"
	// 				fullWidth
	// 				variant="contained"
	// 				color="primary"
	// 				className={classes.submit}
	// 			>
	// 				{i18n.t("companies.form.submit")}
	// 			</Button>
	// 		</>
	// 	}
	// }
	const [modalOpen, setModalOpen] = useState(false);
	const [companies, setCompanies] = useState([])
	const [editEmpresa, setEditEmpresa] = useState(null)
	const {list} = useCompanies()
	const {save} = useCompanies()
	const {remove} = useCompanies()
	const {update} = useCompanies()
	const [hasMore, setHasMore] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [loading, setLoading] = useState(false);
	useEffect(()=>{
		console.log(companies)
		console.log(companies.mensalidade)
	})
	
	//Pegar Empresas
	useEffect(() => {
		const fetchCompanies = async () => {
		  try {
			const data = await list();
			setCompanies(data);
		  } catch (error) {
			console.error("Erro ao procurar", error);
		  }
		};
	
		fetchCompanies();
	  }, [list]);

  	
	const handleOpen = (company = null) => {
		setEditEmpresa(company)
		setModalOpen(true);
	}
 	const handleClose = () => setModalOpen(false);

	const handleAddCompany = async (newCompany) => {
		try{
			setCompanies(prevCompanies => [...prevCompanies, newCompany]);
		} catch (error) {
			console.error("Falha ao adicionar empresa", error)
		}
	};

	const handleDeleteCompany = async (id) => {
		try {
			await remove(id);  
			setCompanies(prevCompanies => prevCompanies.filter(company => company.id !== id));
			toast.success(i18n.t("companies.form.delete"));  
		} catch (error) {
			console.error("Falha ao remover empresa", error);
			toast.error(i18n.t("companies.delete.error")); 
		}
	};
	const [pesquisa, setPesquisa] = useState('');
	const [empresasFiltradas, setEmpresasFiltradas] = useState(companies)

	useEffect(()=> {
		setEmpresasFiltradas(
			companies.filter(company =>
				company.name.toLowerCase().includes(pesquisa.toLocaleLowerCase())
			)
		);
	}, [pesquisa, companies])

	
	const loadMore = () => {
		setPageNumber((prevState) => prevState + 1);
	  };
	
	const handleScroll = (e) => {
		if (!hasMore || loading) return;
		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
		if (scrollHeight - (scrollTop + 100) < clientHeight) {
		  loadMore();
		}
	  };
	
	
	return (
		<>		
		<MainContainer>
			<MainHeader>
				<Title>
					{i18n.t("companies.title")}
				</Title>
				
				<MainHeaderButtonsWrapper>
					<TextField
						placeholder={i18n.t("contacts.searchPlaceholder")}
						type="search"
						value={pesquisa}
						onChange={(e) => setPesquisa(e.target.value)}
						InputProps={{
					 		 startAdornment: (
						<InputAdornment position="start">
						  <SearchIcon style={{ color: "#6c4594" }} />
						</InputAdornment>
					  ),
					}}
					/>
		
						<Button variant="contained" style={{ backgroundColor: "#6c4594" , color:"#fff"}} onClick={handleOpen}>
							Cadastrar Empresa
						</Button>
					<div>	
						<CompModal open={modalOpen} handleClose={handleClose} handleAddCompany={handleAddCompany}/>
					</div>
				</MainHeaderButtonsWrapper>
			</MainHeader>
			{/*<form className={classes.form} noValidate onSubmit={handlSubmit}>
					<Grid container direction="column" spacing={2}>
						{ renderPlanField() }
						{ renderNameField() }
						{ renderTokenField() }
					</Grid>
					{ renderSubmitButton() }
				</form> */}
			 <Paper
        		className={classes.mainPaper}
        		variant="outlined"
        		onScroll={handleScroll}
      		>
			<Table companies = {companies} >
            <TableHead>
                <TableRow>
                    <TableCell  align="center">{i18n.t("companies.table.name")}</TableCell>
					<TableCell  align="center">{i18n.t("companies.table.phone")}</TableCell>
                    <TableCell  align="center">{i18n.t("companies.table.email")}</TableCell>
					{/* <TableCell  align="center">{i18n.t("companies.table.mensalidade")}</TableCell>
					<TableCell  align="center">{i18n.t("companies.table.desconto")}</TableCell> */}
                    <TableCell  align="center">{i18n.t("companies.table.actions")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
               <>
                {empresasFiltradas.map((company) => (
                    <TableRow key={company.id}>
                        <TableCell align="center">{company.name}</TableCell>
						<TableCell align="center">{company.phone}</TableCell>
                        <TableCell align="center">{company.email}</TableCell>
				
                        <TableCell align="center">
                            {/* <IconButton
                            size="small"
					
                            >
                            <EditIcon color="primary"/>
                            </IconButton> */}

                            <IconButton
                            size="small"
							onClick={() => handleDeleteCompany(company.id)}
                            >
                            <DeleteOutlineIcon color="primary"/>
                            </IconButton>
                  </TableCell>
                </TableRow>
                ))}
               </>
            </TableBody>
            </Table>
			</Paper>	
		</MainContainer>
	</>	
	);
};

export default Companies;
