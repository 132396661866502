import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid"; 
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import banner from '../../assets/banner-site-upcall.jpg'
import banner2 from '../../assets/banner2.jpeg'
import bannertelamenor from '../../assets/bannermenor.jpg'
import bannertelamenor2 from '../../assets/bannermenor2.jpg'
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logoupcall.png";




//  import backgroundSvg from '../../assets/background.svg'; 
//  background-image: url('../../assets/background.svg')

const useStyles = makeStyles(theme => ({
	root: {
		width: "100vw",
		height: "100vh",
		//background: "linear-gradient(to right, #586CFA , #586CFA , #4C21A7)", //cor de fundo 
		//backgroundImage: `url(${fundofoto})`, // Substituir o background no assets
		backgroundRepeat: "no-repeat",
		backgroundImage: `url(${banner})`,
		backgroundSize: "100% 100%",
		backgroundPosition: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		// [theme.breakpoints.down('md')]: {
		// 	backgroundImage: `url(${bannertelamenor})`, // Muda para contain em telas menores
		// 	backgroundSize: '100% 100%',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	backgroundImage: `url(${bannertelamenor2})`, // Muda para contain em telas menores
		// 	backgroundSize: '100% 100%',
		// },
	},
	paper: {
		//backgroundColor: theme.palette.login,
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		padding: "55px 30px",
		borderRadius: "12.5px",
		marginLeft: "auto", // Move a div para a direita
		marginRight: "2%", // Adiciona um espaço na direita
		width: "400px", // Defina uma largura fixa se necessário
	},
	container: {
		// position: 'relative',
        // maxWidth: '400px', // Largura máxima
        // margin: 'auto',
		// [theme.breakpoints.down('sm')]: {
		// 	right: '0', // Remove o deslocamento à direita
		// 	marginRight: '0', // Remove a margem direita
		// 	marginLeft: '0',
		// 	alignItems: "center", // Alinha verticalmente no centro
		// 	justifyContent: "center", // Centraliza horizontalmente
		// 	height: "100%", // Ocupa toda a altura do contêiner pai
		// 	margin: 'auto',
		// },
		// [theme.breakpoints.down('md')]: {
		// 	right: '80px', // Remove o deslocamento à direita
			
		// },
		position: 'fixed', // Use absolute para posicionar em relação ao root
        top: '38%',
   		 right: '58%',// Para centralizar verticalmente
        transform: 'translateY(-50%)', // Ajuste para centralização
        maxWidth: '400px', // Largura máxima
        // '@media (max-width: 600px)': { // Para telas menores que 600px
		// 	right: '2%',
        // },
		// '@media (max-width: 700px)': { // Para telas menores que 900px
		// 	right: '2%',
        // },
        // '@media (max-width: 800px)': { // Para telas menores que 900px
			
		// 	left: '60%'
			
        // },
		
		
       
    },
	avatar: {
		margin: theme.spacing(1),  
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "200px", // Fix IE 11 issue.
		justifyContent: 'center',
		margin: "auto",
		WebkitBoxShadow: '0 0 0 1000px transparent inset', // Remove o fundo
		boxShadow: '0 0 0 1000px transparent inset', // Remove o fundo
		backgroundColor: 'transparent', // Mantém o fundo transparente
		
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		//backgroundColor: '#6c454',
		backgroundColor: '#f18a15',
		color :"black",
		'@media (max-width: 780px)': { // Para telas menores que 600px
         	width: '150px',
			height:'30px'
        },
	},
	powered: {
		color: "white"
	},
	link: {
        color:"black" ,
		fontWeight: "lighter"
    },
	copy: {
		
   
	},

	caixa: {
		borderRadius: "8px",
    	//background: 'linear-gradient(45deg, #fafafa 30%, #ebe9c5 90%)',
		background: 'white',
		border: '1px solid #CECECE',
    	filter: 'brightness(1. 1) contrast(1.1)',
    	//boxShadow: '5px 6px 0px rgba(0, 0, 0, 0.3)',
		//borderRadius: "10px",
		color: "black",
		width:'200px',
		height:'50px',
		'& .MuiInput-underline:before': {
		  borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
		  borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:before': {
			borderBottom: 'none',
		  },
		  '& .MuiInput-underline.Mui-focused:before': {
			borderBottom: 'none',
		  },
		'@media (max-width: 780px)': { // Para telas menores que 600px
         	width: '153px',
			height:'47px'
        },
		'& input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px transparent inset',
      boxShadow: '0 0 0 1000px transparent inset',
    },
		// '@media (max-width: 700px)': { // Para telas menores que 900px
        //      width: '150px'
        // },
        // '@media (max-width: 800px)': { // Para telas menores que 900px
        //      width: '160px'
        // },
		// '@media (max-width: 900px)': { // Para telas menores que 900px
        //      width: '170px'
        // },
		// '@media (max-width: 1000px)': { // Para telas menores que 900px
        //     width: '100px'
        // },
		
	  },
}));

const Login = () => {
	const classes = useStyles();

	const [user, setUser] = useState({ email: "", password: "" });

	const { handleLogin } = useContext(AuthContext);
	const Copyright = () => {
		return (
			<Typography variant="body2" className={classes.link} align="center">
				{"Copyright Upcall "}
				 {new Date().getFullYear()}
				 {"."}
			 </Typography>
		 );
	 };

	const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handlSubmit = e => {
		e.preventDefault();
		handleLogin(user);
	};

	const openInNewTab = url => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<div className={classes.root}>
		<Container component="main" maxWidth="xs"  className={classes.paper}>
			<CssBaseline/>
			<div className={classes.container}>
				{/* <div>
					<center><img style={{ margin: "0 auto", width: "50%" }} src={logo} alt="Logologin" /></center>
				</div> */}
				
				<form className={classes.form} noValidate onSubmit={handlSubmit}>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						id="email"
						className={classes.caixa}
						label={i18n.t("login.form.email")}
						name="email"
						value={user.email}
						onChange={handleChangeInput}
						autoComplete="off" // Coloquei pra tirar o background branco que apararece ao selecionar um email salvo
						InputProps={{ style: { color: 'black', padding: '5px'} ,
						inputProps: {
							style: { backgroundColor: 'transparent' }, // ou a cor de fundo desejada
						  },
					}}
						InputLabelProps={{ style: { color: 'black',paddingLeft:'10px',borderRadius: "14px", } }}
					/>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						name="password"
						className={classes.caixa}
						label={i18n.t("login.form.password")}
						type="password"
						id="password"
						value={user.password}
						onChange={handleChangeInput}
						autoComplete="off" // Coloquei pra tirar o background branco que apararece ao selecionar um email salvo
						InputProps={{ style: { color: 'black' , border:'none', padding: '5px'} }}
						InputLabelProps={{ style: { color: 'black' ,paddingLeft:'10px' } }}
						
					/>
						<Button
						type="submit"
						fullWidth
						variant="contained"
						
						className={classes.submit}
					>
						{i18n.t("login.buttons.submit")}
					</Button>
					<Grid container spacing={2}>
				{/*	<Grid item xs={12}>
						<Link
						href="#"
						variant="body2"
						component={RouterLink}
						to="/forgetpsw"
						>
						{i18n.t("Esqueci minha senha")}
						</Link>
					</Grid>*/}
					
					</Grid>

				</form>
				<div className={classes.copy}>
					<Grid item xs={12} className={classes.copy}>
						<Link
						href="#"
						variant="body2"
						component={RouterLink}
						to="/signup"
						className={classes.link}
						>
						{i18n.t("login.buttons.register")}
						</Link>
					</Grid>
					{<Copyright ></Copyright>
					/*	<IconButton color="primary"
								onClick={() => openInNewTab(`https://wa.me/${process.env.REACT_APP_NUMBER_SUPPORT}`)}>
								<WhatsAppIcon style={{ color: "#25D366" }} />
							</IconButton>
					<Typography variant="caption" className={classes.supportText}><b>Fale com suporte</b></Typography>*/}
				</div>
			</div>
		</Container>
		</div>
	);
};

export default Login;

			
			
			